<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Vacaciones</span>
	  		    <v-spacer></v-spacer>
	  		    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( vacaciones  , 'Vacaciones')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="vacaciones"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="error" 
							    		x-small
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idvacaciones }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="editedItem.vacaciones"
            label="Vacaciones"
            filled
            dense
          ></v-text-field>

          <v-autocomplete
            filled
            dense
            clearable
            v-model="editedItem.idtrabajador"
            :items="trabajadores"
            label="Selecciona un trabajador"
            prepend-inner-icon="mdi-account"
            single-line
            item-text="nombre_completo"
            item-value="id"
          >
          </v-autocomplete>

          <v-text-field
            v-model="editedItem.fecha_inicio"
            label="Fecha Inicio"
            filled
            type="date"
            dense
          ></v-text-field>

          <v-text-field
            v-model="editedItem.fecha_fin"
            label="Fecha Fin"
            filled
            type="date"
            dense
          ></v-text-field>

        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los dias de vacaciones?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idvacaciones:0,
        vacaciones:'',
        idtrabajador:0,
        fecha_inicio: '',
        fecha_fin: '',
        date:'',
        fecha_creacion:null,
        deleted:0,
      },

      defaultItem: {
        idvacaciones:0,
        vacaciones:'',
        idtrabajador:0,
        fecha_inicio: '',
        fecha_fin: '',
        date:'',
        fecha_creacion:null,
        deleted:0,
      },

      // Filtros: 
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      vacaciones: [],
      trabajadores: [],
      headers: [
        { text: 'ID'              , value: 'idvacaciones'   },
        { text: 'Vacaciones'      , value: 'vacaciones'     },
        { text: 'Empleado'        , value: 'vacaciones'     },
        { text: 'Fecha Inicio'    , value: 'fecha_inicio'   },
        { text: 'Fecha Fin'       , value: 'fecha_fin'      },
        { text: 'Actions'         , value: 'actions', sortable: false },
      ],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Vacaciones' : 'Editar Vacaciones'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    async created () {
      await this.initialize()
      await this.getTrabajadores()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.vacaciones = []
        return this.$http.get('vacaciones.list').then(response=>{
        	this.vacaciones = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTrabajadores () {
      	this.cargar = true
        this.trabajadores = []
        return this.$http.get('obtener.trabajadores.usuarios').then(response=>{
        	this.trabajadores = response.data.trabajadores
          console.log('trabjadoreeeeessss',this.trabajadores)
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      editItem (item) {
        this.editedIndex = this.vacaciones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.vacaciones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.$http.put('vacaciones.eliminar/' + this.editedItem.idvacaciones, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(this.editedItem.festivo == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('vacaciones.update/' + this.editedItem.idvacaciones, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
            this.close()
          	this.initialize()
	        }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('vacaciones.add', this.editedItem).then(response=>{
            this.close()
          	this.initialize()
	        	this.cargar = false
	        }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

    },
  }
</script>